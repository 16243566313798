exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-ahi-evran-dernegi-mdx": () => import("./../../../src/pages/projects/mdx/ahi-evran-dernegi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-ahi-evran-dernegi-mdx" */),
  "component---src-pages-projects-mdx-akbas-koprusu-mdx": () => import("./../../../src/pages/projects/mdx/akbas-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-akbas-koprusu-mdx" */),
  "component---src-pages-projects-mdx-armutlu-haci-ali-pasa-camii-mdx": () => import("./../../../src/pages/projects/mdx/armutlu-haci-ali-pasa-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-armutlu-haci-ali-pasa-camii-mdx" */),
  "component---src-pages-projects-mdx-aslibey-konagi-mdx": () => import("./../../../src/pages/projects/mdx/aslibey-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-aslibey-konagi-mdx" */),
  "component---src-pages-projects-mdx-berberkaya-lahiti-mdx": () => import("./../../../src/pages/projects/mdx/berberkaya-lahiti.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-berberkaya-lahiti-mdx" */),
  "component---src-pages-projects-mdx-bilecik-hukumet-konagi-mdx": () => import("./../../../src/pages/projects/mdx/bilecik-hukumet-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-bilecik-hukumet-konagi-mdx" */),
  "component---src-pages-projects-mdx-bursa-turk-islam-eserleri-muzesi-mdx": () => import("./../../../src/pages/projects/mdx/bursa-turk-islam-eserleri-muzesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-bursa-turk-islam-eserleri-muzesi-mdx" */),
  "component---src-pages-projects-mdx-cavuslu-camii-mdx": () => import("./../../../src/pages/projects/mdx/cavuslu-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-cavuslu-camii-mdx" */),
  "component---src-pages-projects-mdx-ciha-kalesi-mdx": () => import("./../../../src/pages/projects/mdx/ciha-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-ciha-kalesi-mdx" */),
  "component---src-pages-projects-mdx-dagkaya-koprusu-mdx": () => import("./../../../src/pages/projects/mdx/dagkaya-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-dagkaya-koprusu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-ahi-evran-dernegi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/ahi-evran-dernegi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-ahi-evran-dernegi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-akbas-koprusu-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/akbas-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-akbas-koprusu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-armutlu-haci-ali-pasa-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/armutlu-haci-ali-pasa-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-armutlu-haci-ali-pasa-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-aslibey-konagi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/aslibey-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-aslibey-konagi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-berberkaya-lahiti-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/berberkaya-lahiti.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-berberkaya-lahiti-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-bilecik-hukumet-konagi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/bilecik-hukumet-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-bilecik-hukumet-konagi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-bursa-turk-islam-eserleri-muzesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/bursa-turk-islam-eserleri-muzesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-bursa-turk-islam-eserleri-muzesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-cavuslu-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/cavuslu-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-cavuslu-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-ciha-kalesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/ciha-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-ciha-kalesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-dagkaya-koprusu-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/dagkaya-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-dagkaya-koprusu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-golcuk-roma-donemi-ilicasi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/golcuk-roma-donemi-ilicasi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-golcuk-roma-donemi-ilicasi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-haci-evhaddin-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/haci-evhaddin-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-haci-evhaddin-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-hz-cabir-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/hz-cabir-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-hz-cabir-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-imrahor-ilyas-bey-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/imrahor-ilyas-bey-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-imrahor-ilyas-bey-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-akcakoca-konak-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/izmit-akcakoca-konak.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-akcakoca-konak-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-seymen-antrepo-yapisi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/izmit-seymen-antrepo-yapisi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-seymen-antrepo-yapisi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-veli-oztoprak-evi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/izmit-veli-oztoprak-evi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-izmit-veli-oztoprak-evi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-antik-roma-tiyatrosu-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/iznik-antik-roma-tiyatrosu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-antik-roma-tiyatrosu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-surlari-ikinci-etap-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/iznik-surlari-ikinci-etap.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-surlari-ikinci-etap-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-surlari-lefke-kapi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/iznik-surları-lefke-kapi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-iznik-surlari-lefke-kapi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-kale-i-bala-kalesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/kale-i-bala-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-kale-i-bala-kalesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-merkez-aga-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/merkez-aga-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-merkez-aga-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-merkez-alacahan-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/merkez-alacahan.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-merkez-alacahan-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-onac-ve-dagarcik-kopruleri-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/onac-ve-dagarcik-kopruleri.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-onac-ve-dagarcik-kopruleri-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-orta-hisar-fatih-buyuk-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/orta-hisar-fatih-büyük-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-orta-hisar-fatih-buyuk-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-philokrene-darica-kalesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/philokrene-darica-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-philokrene-darica-kalesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-rauf-pasa-konagi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/rauf-pasa-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-rauf-pasa-konagi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-redif-dairesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/redif-dairesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-redif-dairesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-semaki-evi-muzesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/semaki-evi-muzesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-semaki-evi-muzesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-serik-bizans-donemi-koprusu-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/serik-bizans-donemi-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-serik-bizans-donemi-koprusu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sinop-kalesi-bati-surlari-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/sinop-kalesi-bati-surlari.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sinop-kalesi-bati-surlari-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sivrihisar-kumacik-hamami-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/sivrihisar-kumacik-hamami.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sivrihisar-kumacik-hamami-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sivrihisar-sokak-saglamlastirma-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/sivrihisar-sokak-saglamlastirma.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-sivrihisar-sokak-saglamlastirma-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-talas-sokak-saglamlastirma-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/talas-sokak-saglamlastirma.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-talas-sokak-saglamlastirma-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-tarihi-tas-mektep-mal-hatun-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/tarihi-tas-mektep-mal-hatun.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-tarihi-tas-mektep-mal-hatun-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-taskopru-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/taskopru.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-taskopru-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yagcilar-koprusu-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/yagcilar-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yagcilar-koprusu-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yazicizade-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/yazicizade-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yazicizade-camii-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yildiz-sarayi-cit-kasri-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/yildiz-sarayi-cit-kasri.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yildiz-sarayi-cit-kasri-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yildiz-sarayi-yaveran-dairesi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/yildiz-sarayi-yaveran-dairesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yildiz-sarayi-yaveran-dairesi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yucehisar-koyu-camii-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/vercel/path0/src/pages/projects/mdx/yucehisar-koyu-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-vercel-path-0-src-pages-projects-mdx-yucehisar-koyu-camii-mdx" */),
  "component---src-pages-projects-mdx-golcuk-roma-donemi-ilicasi-mdx": () => import("./../../../src/pages/projects/mdx/golcuk-roma-donemi-ilicasi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-golcuk-roma-donemi-ilicasi-mdx" */),
  "component---src-pages-projects-mdx-haci-evhaddin-camii-mdx": () => import("./../../../src/pages/projects/mdx/haci-evhaddin-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-haci-evhaddin-camii-mdx" */),
  "component---src-pages-projects-mdx-hz-cabir-camii-mdx": () => import("./../../../src/pages/projects/mdx/hz-cabir-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-hz-cabir-camii-mdx" */),
  "component---src-pages-projects-mdx-imrahor-ilyas-bey-camii-mdx": () => import("./../../../src/pages/projects/mdx/imrahor-ilyas-bey-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-imrahor-ilyas-bey-camii-mdx" */),
  "component---src-pages-projects-mdx-izmit-akcakoca-konak-mdx": () => import("./../../../src/pages/projects/mdx/izmit-akcakoca-konak.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-izmit-akcakoca-konak-mdx" */),
  "component---src-pages-projects-mdx-izmit-seymen-antrepo-yapisi-mdx": () => import("./../../../src/pages/projects/mdx/izmit-seymen-antrepo-yapisi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-izmit-seymen-antrepo-yapisi-mdx" */),
  "component---src-pages-projects-mdx-izmit-veli-oztoprak-evi-mdx": () => import("./../../../src/pages/projects/mdx/izmit-veli-oztoprak-evi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-izmit-veli-oztoprak-evi-mdx" */),
  "component---src-pages-projects-mdx-iznik-antik-roma-tiyatrosu-mdx": () => import("./../../../src/pages/projects/mdx/iznik-antik-roma-tiyatrosu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-iznik-antik-roma-tiyatrosu-mdx" */),
  "component---src-pages-projects-mdx-iznik-surlari-ikinci-etap-mdx": () => import("./../../../src/pages/projects/mdx/iznik-surlari-ikinci-etap.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-iznik-surlari-ikinci-etap-mdx" */),
  "component---src-pages-projects-mdx-iznik-surlari-lefke-kapi-mdx": () => import("./../../../src/pages/projects/mdx/iznik-surları-lefke-kapi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-iznik-surlari-lefke-kapi-mdx" */),
  "component---src-pages-projects-mdx-kale-i-bala-kalesi-mdx": () => import("./../../../src/pages/projects/mdx/kale-i-bala-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-kale-i-bala-kalesi-mdx" */),
  "component---src-pages-projects-mdx-merkez-aga-camii-mdx": () => import("./../../../src/pages/projects/mdx/merkez-aga-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-merkez-aga-camii-mdx" */),
  "component---src-pages-projects-mdx-merkez-alacahan-mdx": () => import("./../../../src/pages/projects/mdx/merkez-alacahan.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-merkez-alacahan-mdx" */),
  "component---src-pages-projects-mdx-onac-ve-dagarcik-kopruleri-mdx": () => import("./../../../src/pages/projects/mdx/onac-ve-dagarcik-kopruleri.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-onac-ve-dagarcik-kopruleri-mdx" */),
  "component---src-pages-projects-mdx-orta-hisar-fatih-buyuk-camii-mdx": () => import("./../../../src/pages/projects/mdx/orta-hisar-fatih-büyük-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-orta-hisar-fatih-buyuk-camii-mdx" */),
  "component---src-pages-projects-mdx-philokrene-darica-kalesi-mdx": () => import("./../../../src/pages/projects/mdx/philokrene-darica-kalesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-philokrene-darica-kalesi-mdx" */),
  "component---src-pages-projects-mdx-rauf-pasa-konagi-mdx": () => import("./../../../src/pages/projects/mdx/rauf-pasa-konagi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-rauf-pasa-konagi-mdx" */),
  "component---src-pages-projects-mdx-redif-dairesi-mdx": () => import("./../../../src/pages/projects/mdx/redif-dairesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-redif-dairesi-mdx" */),
  "component---src-pages-projects-mdx-semaki-evi-muzesi-mdx": () => import("./../../../src/pages/projects/mdx/semaki-evi-muzesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-semaki-evi-muzesi-mdx" */),
  "component---src-pages-projects-mdx-serik-bizans-donemi-koprusu-mdx": () => import("./../../../src/pages/projects/mdx/serik-bizans-donemi-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-serik-bizans-donemi-koprusu-mdx" */),
  "component---src-pages-projects-mdx-sinop-kalesi-bati-surlari-mdx": () => import("./../../../src/pages/projects/mdx/sinop-kalesi-bati-surlari.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-sinop-kalesi-bati-surlari-mdx" */),
  "component---src-pages-projects-mdx-sivrihisar-kumacik-hamami-mdx": () => import("./../../../src/pages/projects/mdx/sivrihisar-kumacik-hamami.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-sivrihisar-kumacik-hamami-mdx" */),
  "component---src-pages-projects-mdx-sivrihisar-sokak-saglamlastirma-mdx": () => import("./../../../src/pages/projects/mdx/sivrihisar-sokak-saglamlastirma.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-sivrihisar-sokak-saglamlastirma-mdx" */),
  "component---src-pages-projects-mdx-talas-sokak-saglamlastirma-mdx": () => import("./../../../src/pages/projects/mdx/talas-sokak-saglamlastirma.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-talas-sokak-saglamlastirma-mdx" */),
  "component---src-pages-projects-mdx-tarihi-tas-mektep-mal-hatun-mdx": () => import("./../../../src/pages/projects/mdx/tarihi-tas-mektep-mal-hatun.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-tarihi-tas-mektep-mal-hatun-mdx" */),
  "component---src-pages-projects-mdx-taskopru-mdx": () => import("./../../../src/pages/projects/mdx/taskopru.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-taskopru-mdx" */),
  "component---src-pages-projects-mdx-yagcilar-koprusu-mdx": () => import("./../../../src/pages/projects/mdx/yagcilar-koprusu.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-yagcilar-koprusu-mdx" */),
  "component---src-pages-projects-mdx-yazicizade-camii-mdx": () => import("./../../../src/pages/projects/mdx/yazicizade-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-yazicizade-camii-mdx" */),
  "component---src-pages-projects-mdx-yildiz-sarayi-cit-kasri-mdx": () => import("./../../../src/pages/projects/mdx/yildiz-sarayi-cit-kasri.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-yildiz-sarayi-cit-kasri-mdx" */),
  "component---src-pages-projects-mdx-yildiz-sarayi-yaveran-dairesi-mdx": () => import("./../../../src/pages/projects/mdx/yildiz-sarayi-yaveran-dairesi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-yildiz-sarayi-yaveran-dairesi-mdx" */),
  "component---src-pages-projects-mdx-yucehisar-koyu-camii-mdx": () => import("./../../../src/pages/projects/mdx/yucehisar-koyu-camii.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-yucehisar-koyu-camii-mdx" */)
}

